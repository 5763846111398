<template>
<p>กำลังลงชื่อออก...</p>
</template>

<script>
export default {
  name: 'Signout',
  data(){
    return {
    }
  },
  created() {
      localStorage.removeItem('access_token')
      localStorage.removeItem('email')
      localStorage.removeItem('name')
      localStorage.removeItem('AccType')
      localStorage.removeItem('code')
      localStorage.removeItem('userProfile')
      localStorage.removeItem('Admin')
      window.location.replace(`https://login.microsoftonline.com/${process.env.VUE_APP_TENANT_ID}/oauth2/v2.0/logout?post_logout_redirect_uri=${process.env.VUE_APP_POST_LOGOUT_REDIRECT_URI}`)
  },
}
</script>
